import { default as _91id_93F7g1v8EkX7Meta } from "/tmp/8dd1ec64f27b4d6/pages/applicant/[id].vue?macro=true";
import { default as _91id_93YPEEnzNFitMeta } from "/tmp/8dd1ec64f27b4d6/pages/apply/[id].vue?macro=true";
import { default as _91id_93ZbNUhdnvwGMeta } from "/tmp/8dd1ec64f27b4d6/pages/apply/perfil/[id].vue?macro=true";
import { default as thanksqRu5CkCdykMeta } from "/tmp/8dd1ec64f27b4d6/pages/apply/thanks.vue?macro=true";
import { default as _91_46_46_46slug_93DHYYng2HzaMeta } from "/tmp/8dd1ec64f27b4d6/pages/auth/password/[...slug].vue?macro=true";
import { default as forgotCRjhVwSN67Meta } from "/tmp/8dd1ec64f27b4d6/pages/auth/password/forgot.vue?macro=true";
import { default as _91_46_46_46slug_93riUkGz1CVyMeta } from "/tmp/8dd1ec64f27b4d6/pages/auth/signin/[...slug].vue?macro=true";
import { default as index9yQQ0yEeGmMeta } from "/tmp/8dd1ec64f27b4d6/pages/auth/signin/index.vue?macro=true";
import { default as _91email_93sg6YAxmILqMeta } from "/tmp/8dd1ec64f27b4d6/pages/auth/signup/[email].vue?macro=true";
import { default as index57T9urOOsQMeta } from "/tmp/8dd1ec64f27b4d6/pages/auth/signup/index.vue?macro=true";
import { default as _91_46_46_46slug_93dGai5BzjMUMeta } from "/tmp/8dd1ec64f27b4d6/pages/auth/verifyemail/[...slug].vue?macro=true";
import { default as jobs1Cgw5u7rLFMeta } from "/tmp/8dd1ec64f27b4d6/pages/company/[id]/jobs.vue?macro=true";
import { default as indexjqnXMpb8AIMeta } from "/tmp/8dd1ec64f27b4d6/pages/company/index.vue?macro=true";
import { default as onboardingvunj8be3v1Meta } from "/tmp/8dd1ec64f27b4d6/pages/company/onboarding.vue?macro=true";
import { default as index33CGRYH8LZMeta } from "/tmp/8dd1ec64f27b4d6/pages/index.vue?macro=true";
import { default as _91applicant_93t3kv0ZzrHiMeta } from "/tmp/8dd1ec64f27b4d6/pages/job/[job]/applicants/[applicant].vue?macro=true";
import { default as indexBaUptRwKFmMeta } from "/tmp/8dd1ec64f27b4d6/pages/job/[job]/applicants/index.vue?macro=true";
import { default as duplicatekJ4OJZVgHuMeta } from "/tmp/8dd1ec64f27b4d6/pages/job/[job]/duplicate.vue?macro=true";
import { default as indexTDWRTMM8l3Meta } from "/tmp/8dd1ec64f27b4d6/pages/job/[job]/index.vue?macro=true";
import { default as archived76INZ5FykrMeta } from "/tmp/8dd1ec64f27b4d6/pages/job/archived.vue?macro=true";
import { default as closed4Q5paPIgv8Meta } from "/tmp/8dd1ec64f27b4d6/pages/job/closed.vue?macro=true";
import { default as newgbTdyH0kVeMeta } from "/tmp/8dd1ec64f27b4d6/pages/job/new.vue?macro=true";
import { default as openedNfOtULbVDAMeta } from "/tmp/8dd1ec64f27b4d6/pages/job/opened.vue?macro=true";
import { default as pausedX2sk52efMQMeta } from "/tmp/8dd1ec64f27b4d6/pages/job/paused.vue?macro=true";
import { default as indexDBXHiUdeNpMeta } from "/tmp/8dd1ec64f27b4d6/pages/plans/index.vue?macro=true";
import { default as settingsylpqh5LqlHMeta } from "/tmp/8dd1ec64f27b4d6/pages/settings.vue?macro=true";
import { default as index7fFGxUvrfhMeta } from "/tmp/8dd1ec64f27b4d6/pages/team/index.vue?macro=true";
import { default as indexzuUchidEN5Meta } from "/tmp/8dd1ec64f27b4d6/pages/thanks/index.vue?macro=true";
import { default as indexI0zmDeo91mMeta } from "/tmp/8dd1ec64f27b4d6/pages/user/index.vue?macro=true";
export default [
  {
    name: "applicant-id",
    path: "/applicant/:id()",
    meta: _91id_93F7g1v8EkX7Meta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/applicant/[id].vue")
  },
  {
    name: "apply-id",
    path: "/apply/:id()",
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/apply/[id].vue")
  },
  {
    name: "apply-perfil-id",
    path: "/apply/perfil/:id()",
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/apply/perfil/[id].vue")
  },
  {
    name: "apply-thanks",
    path: "/apply/thanks",
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/apply/thanks.vue")
  },
  {
    name: "auth-password-slug",
    path: "/auth/password/:slug(.*)*",
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/auth/password/[...slug].vue")
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-signin-slug",
    path: "/auth/signin/:slug(.*)*",
    meta: _91_46_46_46slug_93riUkGz1CVyMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/auth/signin/[...slug].vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: index9yQQ0yEeGmMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/auth/signin/index.vue")
  },
  {
    name: "auth-signup-email",
    path: "/auth/signup/:email()",
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/auth/signup/[email].vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: index57T9urOOsQMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/auth/signup/index.vue")
  },
  {
    name: "auth-verifyemail-slug",
    path: "/auth/verifyemail/:slug(.*)*",
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/auth/verifyemail/[...slug].vue")
  },
  {
    name: "company-id-jobs",
    path: "/company/:id()/jobs",
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/company/[id]/jobs.vue")
  },
  {
    name: "company",
    path: "/company",
    meta: indexjqnXMpb8AIMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/company/index.vue")
  },
  {
    name: "company-onboarding",
    path: "/company/onboarding",
    meta: onboardingvunj8be3v1Meta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/company/onboarding.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index33CGRYH8LZMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/index.vue")
  },
  {
    name: "job-job-applicants-applicant",
    path: "/job/:job()/applicants/:applicant()",
    meta: _91applicant_93t3kv0ZzrHiMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/job/[job]/applicants/[applicant].vue")
  },
  {
    name: "job-job-applicants",
    path: "/job/:job()/applicants",
    meta: indexBaUptRwKFmMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/job/[job]/applicants/index.vue")
  },
  {
    name: "job-job-duplicate",
    path: "/job/:job()/duplicate",
    meta: duplicatekJ4OJZVgHuMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/job/[job]/duplicate.vue")
  },
  {
    name: "job-job",
    path: "/job/:job()",
    meta: indexTDWRTMM8l3Meta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/job/[job]/index.vue")
  },
  {
    name: "job-archived",
    path: "/job/archived",
    meta: archived76INZ5FykrMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/job/archived.vue")
  },
  {
    name: "job-closed",
    path: "/job/closed",
    meta: closed4Q5paPIgv8Meta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/job/closed.vue")
  },
  {
    name: "job-new",
    path: "/job/new",
    meta: newgbTdyH0kVeMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/job/new.vue")
  },
  {
    name: "job-opened",
    path: "/job/opened",
    meta: openedNfOtULbVDAMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/job/opened.vue")
  },
  {
    name: "job-paused",
    path: "/job/paused",
    meta: pausedX2sk52efMQMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/job/paused.vue")
  },
  {
    name: "plans",
    path: "/plans",
    meta: indexDBXHiUdeNpMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/plans/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsylpqh5LqlHMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/settings.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: index7fFGxUvrfhMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/team/index.vue")
  },
  {
    name: "thanks",
    path: "/thanks",
    meta: indexzuUchidEN5Meta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/thanks/index.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexI0zmDeo91mMeta || {},
    component: () => import("/tmp/8dd1ec64f27b4d6/pages/user/index.vue")
  }
]